
@import '~leaflet';
@import '../scss/config';

.imagine-map {
    height: 400px;
    width: 100%;
    z-index: 0;
}

.imagine-map-icon__marker {
    width: 30px;
    height: 30px;
    border-radius: 50% 50% 50% 0;
    background: $mdc-theme-primary;
    position: absolute;
    transform: rotate(-45deg);
    left: 50%;
    top: 50%;
    margin: -15px 0 0 -15px;
}

.imagine-map-icon__marker::after {
    content: '';
    width: 24px;
    height: 24px;
    margin: 3px 0 0 3px;
    background: #ffffff;
    position: absolute;
    border-radius: 50%;
}

.imagine-map-icon i {
    position: absolute;
    width: 22px;
    font-size: 22px;
    left: 0;
    right: 0;
    margin: 10px auto;
    text-align: center;
}
