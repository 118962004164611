
@import '../scss/config';

.imagine-logo {
    font-size: 1.5rem;
    color: grey;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 60px;
    margin: 1rem 0;

    svg {
        .letter {
            fill: $mdc-theme-secondary;
            fill: var(--mdc-theme-secondary, $mdc-theme-secondary);

            &.letter--primary {
                fill: $mdc-theme-primary;
                fill: var(--mdc-theme-primary, $mdc-theme-primary);
            }
        }
    }
}
