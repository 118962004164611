
@import '../scss/config';

.property-edit {
    &__form {
        &__floor, &__division, &__building {
            flex: unset;
            width: 70px;
        }

        &__transactions {
            .imagine-checkboxes__container {
                margin: .5rem auto;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        &__prices {
            .md-field {
                margin-bottom: 0;
            }
        }

        &__furnished {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    &__timeline {
        margin-top: 1rem;
    }

    &__transactions {
        .imagine-status {
            font-size: 1.2rem;
        }

        &__category {
            margin: 1rem 0;

            &__title {
                margin: 0 0 .5rem;
                padding: 0 1rem;
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            p {
                padding: 0.5rem 1rem;
            }
        }
    }

    &__prospects {
        &__header {
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }

        &__prospect {
            &__score {
                text-align: right;
            }
        }

        p {
            padding: 0.5rem 1rem;
        }
    }
}
