
@import '../../scss/config';

.contacts {
    &__filters {
        &__filters {
            @media (min-width: map-get($imagine-breakpoints, 'desktop')) {
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
            }

            .md-field {
                margin: 0 0 .5rem;
            }

            &__group {
                flex: 1;
                border: 1px dotted #ffffff;

                &--infos, &--client {
                    flex: 0 0 25%;
                }

                @media (min-width: map-get($imagine-breakpoints, 'desktop')) {
                    &--prospect {
                        .contacts__filters__filters__group__body {
                            display: flex;
                            margin-left: -.25rem;
                            margin-right: -.25rem;

                            .contacts__filters__filters__group__body__group {
                                padding: 0 .25rem;
                                flex: 0 0 50%
                            }
                        }
                    }
                }

                &__title {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    cursor: pointer;
                    padding: .5rem 1rem;

                    h6 {
                        margin: 0;
                        display: flex;
                        align-items: center;

                        .material-icons {
                            margin-left: .5rem;
                        }
                    }
                }

                &__body {
                    margin-top: .5rem;
                    padding: .5rem 1rem;

                    &__group {
                        &__criteria {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            height: 56px;
                            cursor: pointer;
                            font-size: 1rem;

                            .mdc-select__menu {
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
}
