
@import '../scss/config';

.contact-edit {
    &__infos {
        &__company {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &__title {
            flex: unset;
            width: 80px;
        }

        &__age {
            flex: unset;
            width: 150px;
        }

        &__timeline {
            margin-top: 1rem;
        }

        &__ads {
            display: flex;
            align-items: center;
        }

        &__note {
            &.md-field {
                margin-bottom: 0;
            }
        }

        &__legal-represents {
            p {
                margin: 0 0 1rem;
                text-align: center;
                font-size: 1rem;
            }
        }

        &__legal-rep {
            &__title {
                margin: .5rem 0 1rem;
                text-align: center;
                font-size: 1.2rem;
            }
        }

        .imagine-form__actions {
            margin-top: 3rem;
        }
    }

    &__prospect {
        &__salesrep {
            .imagine-form__row__field {
                margin-bottom: 0;
            }
        }

        &__transactions {
            display: flex;
            align-items: center;
            justify-content: center;

            .imagine-checkboxes__container {
                margin: 1rem auto;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        .imagine-form__actions {
            margin-top: 1rem;
        }
    }

    &__client {
        &__category {
            margin: 1rem 0;

            &__title {
                margin: 0 0 .5rem;
                padding: 0 1rem;
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            p {
                padding: 0.5rem 1rem;
            }
        }

        .imagine-status {
            font-size: 1.2rem;
        }
    }

    .imagine-checkareas {
        display: inline-block;
        margin-right: 1rem;
    }
}
