
@import '../scss/config';
@import "~@material/list/mdc-list";

.employees {
    &__list {
        padding: 0;

        &__employee {
            &.mdc-list-item--disabled {
                cursor: pointer;

                .mdc-list-item__meta {
                    span {
                        color: $imagine-error-color;
                    }
                }
            }
        }
    }
}
