
.imagine-select {
    .md-select {
        .md-icon {
            position: absolute;
            right: .5rem;
            top: 12px;
        }
    }
}
