$mdc-theme-primary: #2D5886;
$mdc-theme-secondary: #2893D0;
$mdc-theme-background: #fff;

.target--staging {
  --mdc-theme-primary: black;
  --mdc-theme-secondary: grey;
}

$imagine-error-color: #b00020;
$imagine-orange: orange;
$imagine-green: green;

$imagine-container-width: 1200px;
$imagine-breakpoints: (
        desktop: 1000px,
        phone: 500px
)
