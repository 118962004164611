
@import '../scss/config';
@import '~@material/dialog/mdc-dialog';

.imagine-modal {
    z-index: 101;

    &--booking,
    &--wide {
        .mdc-dialog__container, .mdc-dialog__surface {
            width: 100%;
        }
    }

    &__title-inner {
        margin-top: -40px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &__side {
            &.mdc-icon-button {
                display: none;
            }
        }
    }

    @media (min-width: 1440px) {
        &--side-right {
            left: 30%;
        }

        &--side-left {
            left: -30%;
        }

        &__title-inner {
            &__side {
                &.mdc-icon-button {
                    display: inline-block;
                }
            }
        }
    }

    .mdc-dialog__surface {
        position: relative;
    }

    .imagine-modal__close {
        cursor: pointer;
        position: absolute;
        right: 0;
        top: 0;
        background: transparent;
        border: none;

        &:focus {
            outline: 0;
        }
    }
}

.md-menu-content.md-select-menu, .md-menu-content {
    z-index: 102;
}
