
@import '../scss/config';

.sale {
    .md-steppers.md-vertical .md-stepper-content {
        padding: 0 14px 0 50px;
    }

    &__price-consistency {
        padding-top: .5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-style: italic;

        &--error {
            color: #ff1744;
        }
    }

    &__option {
        margin-bottom: 1rem;

        &__alert {
            margin: .5rem;
        }
    }

    &__buyer {
        padding: 0 .5rem;
        display: flex;
        align-items: flex-start;

        &__goto {
            margin-top: .5rem;
        }
    }

    &__meta {
        .md-field {
            margin-bottom: 0;
        }
    }

    &__step--force-disabled {
        pointer-events: none;
    }
}
