
@import '../scss/config';
@import '~@material/tab-bar/mdc-tab-bar';
@import '~@material/tab-scroller/mdc-tab-scroller';
@import '~@material/tab-indicator/mdc-tab-indicator';
@import '~@material/tab/mdc-tab';

.imagine-tab {
    &.imagine-tab--disabled {
        opacity: .3;
        pointer-events: none;
    }

    .mdc-tab__icon {
        @media (max-width: map-get($imagine-breakpoints, 'phone')) {
            display: none;
        }
    }
}
