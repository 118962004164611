
@import '../scss/config';
@import '~@material/list/mdc-list';

.blocks {
    &__filter {
        padding: .5rem .5rem .2rem;

        .md-field {
            margin-bottom: 8px;
        }
    }
}
