
@import '../../scss/config';

.properties {
    &__filters {
        padding: 8px 8px 2px;

        @media (min-width: map-get($imagine-breakpoints, 'desktop')) {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        &__filter {
            flex: 1;
            margin-left: 8px;
            margin-right: 8px;
            margin-bottom: 8px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 56px;
            cursor: pointer;

            &--bordered {
                border: 1px dotted #ffffff;
                padding: .5rem;
            }

            .md-field {
                margin: 0;
            }
        }
    }

    &__count {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1rem;

        &.properties__count--filtered {
            .properties__count__count {
                font-weight: 500;
            }
        }

        &__sort {
            &__select {
                background: transparent;
            }
        }
    }

    &__see-more {
        cursor: pointer;
        text-align: center;
        padding: 1rem;
    }

    &__map {
        padding: .5rem 1rem;

        .imagine-map {
            height: 65vh;
        }
    }
}
