
@import '../../scss/config';
@import '~@material/list/mdc-list';

.contacts {
    &__count {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1rem;

        &.contacts__count--filtered {
            .contacts__count__count {
                font-weight: 500;
            }
        }
    }

    &__contact {
        &__lastname, &__company {
            font-weight: 500;
        }
    }

    &__see-more {
        cursor: pointer;
        text-align: center;
        padding: 1rem;
    }
}
