
@import '../scss/config';

.imagine-file {

    &__preview {
        text-align: center;
        cursor: pointer;

        img {
            max-width: 350px;
        }

        .material-icons-outlined {
            font-size: 72px;
        }
    }

    &--rounded {
        .imagine-file__preview {
            img {
                border-radius: 160px;
            }
        }
    }

    &__input {
        &--error {
            &.md-field.md-theme-default .md-helper-text, &.md-field.md-theme-default .md-count, &.md-field.md-theme-default label {
                color: $imagine-error-color;
            }
        }

        input {
            width: 100%;
        }

        &.md-field .md-icon {
            margin: -4px auto;
        }
    }
}
