
@import '../scss/config';
@import '~@material/snackbar/mdc-snackbar';

.mdc-snackbar {
    z-index: 100;
}

.imagine-message.imagine-message--success .mdc-snackbar__surface {
    background: $mdc-theme-secondary;
    background: var(--mdc-theme-secondary, $mdc-theme-secondary);
    color: white;
    border-color: white;
}

.imagine-message.imagine-message--error .mdc-snackbar__surface {
    background: $imagine-error-color;
    color: white;
    border-color: white;
}

.mdc-snackbar__surface .mdc-snackbar__action {
    color: white;
}
