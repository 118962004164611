
@import '../scss/config';

.sale-payments {
    &__header {
        padding: 1rem;

        &__info {
            font-size: 1rem;
            display: flex;
            align-items: center;
            margin: .5rem 0;

            &--price {
                span {
                    width: 140px;
                    text-align: right;
                }
            }

            &--total {
                span {
                    border-top: 1px dashed #000000;
                    font-weight: bold;
                    padding-top: 2px;
                }
            }

            b {
                width: 180px;
            }
        }
    }

    &__none {
        padding: 2rem;
        text-align: center;
        font-size: 1.25rem;
    }
}
