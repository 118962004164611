
@import '../scss/config';
@import '~@material/data-table/mdc-data-table';

.block-edit {
    .imagine-form__row__field--acronym {
        flex: unset;
        width: 100px;
    }

    .imagine-form__row__field--bic {
        flex: unset;
        width: 100px;
    }

    &__divisions {
        &__actions {
            text-align: right;
            padding: 1rem;
        }

        &__summary {
            padding: 1rem;
            text-align: center;

            &__status {
                width: 70%;
                padding: 0;
                text-align: center;

                &__progress {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    &__count {
                        text-align: right;
                        width: 95px;
                    }
                }
            }

            .mdc-data-table {
                @include mdc-data-table-density(-4);
                width: 100%;
            }
        }

        &__confirm {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &__none {
            padding: 2rem;
            text-align: center;
            font-size: 1.25rem;
        }
    }

    &__planning {
        &__actions {
            text-align: right;
            padding: 1rem;
        }

        &__stages {
            text-align: center;

            &__stage {
                margin: 2rem 0;

                &__title {
                    font-size: 1.2rem;
                    font-weight: 500;
                    margin: 0;
                }
            }
        }

        &__none {
            padding: 2rem;
            text-align: center;
            font-size: 1.25rem;
        }
    }

    .imagine-status {
        font-size: 1.2rem;
    }
}
