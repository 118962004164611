
@import '../scss/config';
@import '~@material/list/mdc-list';

.mandate {
    &__readonly {
        flex: 1;
        font-size: 1rem;

        .mdc-list {
            padding: 0;
        }
    }

    &__isexclusive {
        @media (min-width: map-get($imagine-breakpoints, 'phone')) {
            text-align: right;
            flex: 0 0 150px;
            margin-bottom: 1.5rem;
        }
    }
}

.mandate-leases {
    &__title {
        font-weight: normal;
        font-size: 1.2rem;
        margin: 2rem .5rem .5rem;
    }

    &__tenant {
        cursor: pointer;
    }

    &__none {
        padding: 2rem;
        text-align: center;
        font-size: 1.25rem;
    }
}

.mandate-sale {
    &__none {
        padding: 2rem;
        text-align: center;
        font-size: 1.25rem;
    }
}
