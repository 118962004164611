
@import '../scss/config';
@import '~@material/elevation/mdc-elevation';

.imagine-scroll-top.mdc-icon-button {
    border: 2px solid #000000;
    border-color: $mdc-theme-primary;
    border-color: var(--mdc-theme-primary, $mdc-theme-primary);
    border-radius: 3rem;
    color: $mdc-theme-primary;
    color: var(--mdc-theme-primary, $mdc-theme-primary);
    position: fixed;
    bottom: 1rem;
    padding: 10px;
    z-index: 10;
    right: 1rem;
    background: white;
}
