
@import '../scss/config';
@import '~@material/elevation/mdc-elevation';

.imagine-login {
    display: flex;
    height: 100vh;
    background: #f5f5f5;

    .imagine-logo {
        margin-top: 0;
    }

    &__panel {
        background: #ffffff;
        margin: auto;
        padding: 2rem;
        min-width: 300px;
        max-width: 480px;

        .md-field {
            background: white;
            padding-left: 0;

            label {
                padding-left: 0;
            }
        }

        .imagine-button {
            margin-top: 1rem;
            width: 100%;
        }
    }
}
