
@import '../scss/config';
@import '~@material/list/mdc-list';

.sales {
    &__filters {
        padding: 8px 8px 2px;

        @media (min-width: map-get($imagine-breakpoints, 'desktop')) {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        &__filter {
            flex: 1;
            margin-left: 8px;
            margin-right: 8px;
            margin-bottom: 8px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 56px;
            cursor: pointer;

            &--bordered {
                border: 1px dotted #ffffff;
                padding: .5rem;
            }

            .md-field {
                margin: 0;
            }

            &--statuses {
                .md-menu.md-select {
                    flex: unset;
                    width: 90%;

                    .md-input {
                        font-size: .9rem;
                    }
                }
            }
        }
    }

    &__stats {
        &__none {
            padding: 2rem;
            text-align: center;
            font-size: 1.25rem;
        }
    }
}
