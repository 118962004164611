
.imagine-error {
    background-color: #ffffff;
    font: 14px Roboto, sans-serif;
    display: flex;
    height: 100vh;

    &__message {
        margin: auto;
        padding: 1rem;
        text-align: center;

        h1 {
            font-size: 1.8rem;
            font-weight: 500;
        }

        .imagine-logo {
            width: 250px;
            margin: 1rem auto;
        }
    }
}
