
.imagine-form__complex-field {
    padding: .5rem;

    &--show {
        .imagine-form__complex-field__header {
            border: 1px dotted rgba(0, 0, 0, .42);
        }
    }

    &__header {
        color: rgba(0, 0, 0, 0.6);
        background-color: whitesmoke;
        border-radius: 4px 4px 0 0;
        border-bottom: 1px dotted rgba(0, 0, 0, .42);
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1rem;

        &__title {
            margin: 0;
            font-size: 1rem;
            font-weight: normal;
        }
    }

    &--disabled {
        .imagine-form__complex-field__header {
            color: rgba(0, 0, 0, 0.3);
        }
    }

    &__body {
        border-radius: 0 0 4px 4px;
        border: 1px dotted rgba(0, 0, 0, .42);
        border-top: 0;
        padding: .5rem;
    }
}
