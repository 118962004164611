
.mailing-detail {
    &__break {
        flex-basis: 100%;
        height: 0;
    }

    &__preview {
        background: #efefef;
        padding: .5rem;

        &__section {
            border-bottom: 1px dotted;
            padding: .5rem;
        }
    }

    &__stats {
        background: #efefef;
        padding: .5rem;
        display: flex;
        align-items: center;
        justify-content: space-between;;

        &__stat {
            font-size: .85rem;
            border: 1px dotted;
            padding: 0 .5rem;
            margin: 0 .5rem;
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
        }
    }

    &__nostats {
        background: #efefef;
        text-align: center;
        font-size: 1.2rem;
        padding: 1rem;
    }
}
