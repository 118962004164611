
@import '../scss/config';
@import '~@material/elevation/mdc-elevation';

.employee-edit {
    &__avatar-row {
        display: flex;
        align-items: center;

        &__photo {
            width: 275px;

            @media (max-width: map-get($imagine-breakpoints, 'phone')) {
                display: none;
            }

            .imagine-file__preview {
                img {
                    width: 200px;
                }
            }
        }

        &__infos {
            flex: 1;
        }
    }

    &__prospects {
        text-align: center;
    }
}
