
@import '../scss/config';

.imagine-alert {
    padding: 1rem;
    margin: .5rem 0;
    font-size: 1rem;

    display: flex;
    align-items: center;

    &__message {
        margin: 0 0 0 1rem;
    }

    &--warning {
        background: $imagine-error-color;
        color: #ffffff;
    }

    &--info {
        background: $mdc-theme-secondary;
        color: #ffffff;
    }

    a {
        color: #ffffff !important;
        text-decoration: underline !important;
    }
}
