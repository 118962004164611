
.imagine-checkboxes {
    display: flex;
    flex-direction: column;

    &__label {
        margin: 0 0 .5rem;

        &.imagine-checkboxes__label--disabled {
            color: rgba(0, 0, 0, 0.37);
            cursor: default;
        }
    }

    &__container {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        .imagine-checkbox {
            margin-right: 1rem;
        }
    }
}
