
.imagine-datepicker {
    &--disabled {
        color: rgba(0, 0, 0, 0.3);
        -webkit-text-fill-color: rgba(0, 0, 0, 0.3);

        label, input {
            color: rgba(0, 0, 0, 0.3);
            -webkit-text-fill-color: rgba(0, 0, 0, 0.3) !important;
        }
    }

    &--dense {
        height: 36px;
        min-height: 36px;
        padding-top: 10px;
        margin-top: 0;

        &.md-has-value.md-field label {
            top: 0;
        }

        &.md-field label {
            top: 10px;
        }
    }

    input {
        width: 100%;
    }

    &.md-field > .md-icon {
        margin: -4px auto;
    }

    &.md-field .md-input-action {
        top: 8px;
    }
}

.md-datepicker-dialog {
    z-index: 101;
}
