
@import '../scss/config';
@import '~@material/icon-button/mdc-icon-button';

.imagine-icon-button.imagine-icon-button--active {
    &:after {
        background-color: #ffffff !important;
        opacity: .24 !important;
    }
}
