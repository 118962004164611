
.imagine-input {
    .md-input {
        width: 100%;
    }

    &.md-field > .md-icon {
        margin: -4px auto;
    }

    &.md-field > .md-icon ~ .md-input {
        margin-left: 0;
    }

    &.md-field .md-input-action {
        top: 12px;
    }

    &.md-field > .md-icon:after {
        width: 24px;
    }
}
