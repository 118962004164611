
@import '../scss/config';
@import '~@material/form-field/mdc-form-field';
@import '~@material/checkbox/mdc-checkbox';

.imagine-checkbox {
    &__label {
        cursor: pointer;

        &.imagine-checkbox__label--disabled {
            color: rgba(0, 0, 0, 0.37);
            cursor: default;
        }
    }
}
