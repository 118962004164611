
@import '../scss/config';
@import '~@material/theme/variables';
@import '~@material/linear-progress/mdc-linear-progress';

.imagine-loader {
    position: fixed;
    z-index: 10;
    width: 100%;

    &.imagine-loader--with-message {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100vh;

        .imagine-loader__message {
            margin: 2rem;
            color: #666666;
            font-size: 1.5rem;
            text-transform: uppercase;
            text-align: center;
            line-height: 2rem;
        }

        .imagine-loader__progress {
            width: 80%;
        }
    }
}
