
@import '../scss/config';
@import '~@material/drawer/mdc-drawer';
@import '~@material/list/mdc-list';
@import '~@material/top-app-bar/mdc-top-app-bar';
@import '~@material/icon-button/mdc-icon-button';

@media (min-width: map-get($imagine-breakpoints, 'desktop')) {
    .mdc-drawer {
        display: flex !important;
    }
}

@media (min-width: map-get($imagine-breakpoints, 'desktop')) {
    .imagine-layout__header__toggle {
        display: none;
    }
}

.imagine-layout {
    display: flex;
    height: 100vh;

    &__user-menu {
        position: fixed;
        top: 64px;
        right: 1rem;
        z-index: 1;
    }

    .mdc-drawer-app-content {
        min-width: 300px;
        width: 100%;

        @media (min-width: map-get($imagine-breakpoints, 'desktop')) {
            left: 256px;
            width: calc(100% - 256px);
        }
    }

    .mdc-top-app-bar {
        left: 0;
        @media (min-width: map-get($imagine-breakpoints, 'desktop')) {
            left: 256px;
        }

        &.imagine-layout__header--with-sidebar {
            left: 256px;
        }

        right: 0;
        width: auto;
        z-index: 10;
    }

    @media (min-width: map-get($imagine-breakpoints, 'desktop')) {
        .mdc-top-app-bar__section {
            padding-left: 1rem;
            padding-right: 1rem;
        }
    }

    .mdc-top-app-bar__title {
        padding-left: 0;
    }

    .mdc-menu {
        background-color: #ffffff;
    }

    .imagine-logo {
        margin-bottom: 0;
    }

    &__header {
        &__brand {
            @media (min-width: map-get($imagine-breakpoints, 'desktop')) {
                display: none;
            }

            height: 32px;
            width: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    &__sidebar {
        position: fixed;
        z-index: 100;

        &__logo {
            cursor: pointer;
        }

        &__user-infos {
            margin: 1rem 0 .5rem;
            display: flex;
            align-items: center;
            justify-content: space-between;

            &__name {
                &:before, &:after {
                    content: none;
                }

                margin: 0;
            }

            &__email {
                &:before, &:after {
                    content: none;
                }

                margin: 0;
                word-break: break-all;
            }

            &__picture {
                border-radius: 18px;
                width: 36px;
                height: 36px;
                margin-right: .5rem;
            }
        }

        &__menu {
            display: flex;
            flex-direction: column;

            &__list {
                flex: 1;

                a, a:not(.md-button) {
                    color: #000000;
                }
            }

            &__footer {
                font-size: .8rem;
                padding: .5rem;
            }
        }
    }

    &__workspace {
        position: relative;
        padding-bottom: 4rem;

        @media (min-width: map-get($imagine-breakpoints, 'desktop')) {
            .imagine-loader {
                left: 256px;
                width: calc(100% - 256px);
            }
        }
    }
}
