
@import '../scss/config';
@import '~@material/list/mdc-list';

.mailings {
    &__none {
        padding: 2rem;
        text-align: center;
        font-size: 1.25rem;
    }
}

